import React from "react";
import Walltrim1 from "../assets/walltrim1.jpg";
import Walltrim2 from "../assets/walltrim2.jpg";
import Supportbeam1 from "../assets/supportbeam1.jpg";
import Supportbeam2 from "../assets/supportbeam2.jpg";
import Slidingdoor1 from "../assets/slidingdoor1.jpg";
import Slidingdoor2 from "../assets/slidingdoor2.jpg";
import Pergola1 from "../assets/pergola1.jpeg";
import Pergola2 from "../assets/pergola2.jpg";
import Glassdoor1 from "../assets/glassdoor1.jpg";
import Glassdoor2 from "../assets/glassdoor2.jpg";


const Work = () => {
  return (
    <div name="work" className="w-full md:h-full text-[#031e65]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline ">Work</p>
          <p className="text-lg text-[#000000] py-6">
            Here are a few of my recent projects.
          </p>
        </div>

        {/* Container */}
        <div className="grid sm:grid-cols-2 md:grid-cols-2 gap-4">
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Walltrim1})` }}
            className="shadow-lg shadow-[#3a3a3e] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-[#ffffff] tracking-wider">
                Wall Trim
              </span>
              <div className="pt-8 text-center">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#112a70ba] text-[#ffffff] font-bold text-lg">
                    Before
                  </button>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Walltrim2})` }}
            className="shadow-lg shadow-[#3a3a3e] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-[#ffffff] tracking-wider">
                Wall Trim
              </span>
              <div className="pt-8 text-center">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#112a70ba] text-[#ffffff] font-bold text-lg">
                    After
                  </button>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Supportbeam1})` }}
            className="shadow-lg shadow-[#3a3a3e] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-[#ffffff] tracking-wider">
                Support Beam
              </span>
              <div className="pt-8 text-center">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#112a70ba] text-[#ffffff] font-bold text-lg">
                    Before
                  </button>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Supportbeam2})` }}
            className="shadow-lg shadow-[#3a3a3e] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-[#ffffff] tracking-wider">
                Support Beam
              </span>
              <div className="pt-8 text-center">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#112a70ba] text-[#ffffff] font-bold text-lg">
                    After
                  </button>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Slidingdoor1})` }}
            className="shadow-lg shadow-[#3a3a3e] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-[#ffffff] tracking-wider">
                Sliding Door
              </span>
              <div className="pt-8 text-center">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#112a70ba] text-[#ffffff] font-bold text-lg">
                    Before
                  </button>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Slidingdoor2})` }}
            className="shadow-lg shadow-[#3a3a3e] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-[#ffffff] tracking-wider">
                Sliding Door
              </span>
              <div className="pt-8 text-center">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#112a70ba] text-[#ffffff] font-bold text-lg">
                    After
                  </button>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Pergola1})` }}
            className="shadow-lg shadow-[#3a3a3e] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-[#ffffff] tracking-wider">
                Pergola
              </span>
              <div className="pt-8 text-center">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#112a70ba] text-[#ffffff] font-bold text-lg">
                    Before
                  </button>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Pergola2})` }}
            className="shadow-lg shadow-[#3a3a3e] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-[#ffffff] tracking-wider">
                Pergola
              </span>
              <div className="pt-8 text-center">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#112a70ba] text-[#ffffff] font-bold text-lg">
                    After
                  </button>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Glassdoor1})` }}
            className="shadow-lg shadow-[#3a3a3e] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-[#ffffff] tracking-wider">
                Glass Door
              </span>
              <div className="pt-8 text-center">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#112a70ba] text-[#ffffff] font-bold text-lg">
                    Before
                  </button>
              </div>
            </div>
          </div>
          {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Glassdoor2})` }}
            className="shadow-lg shadow-[#3a3a3e] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-[#ffffff] tracking-wider">
                Glass Door
              </span>
              <div className="pt-8 text-center">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#112a70ba] text-[#ffffff] font-bold text-lg">
                    After
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
