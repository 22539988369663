import React from "react";

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full h-screen  flex justify-center items-center p-4"
    >
      <form
        method="POST"
        action="https://getform.io/f/7d228d5d-4299-4a61-8d94-d513137ca08a"
        className="flex flex-col max-w-[600px]"
      >
        <div className="pb-8">
          <p className="text-4xl font-bold inline text-[#031e65]">Contact</p>
          <p className="text-lg py-4">
            Complete the form below with your details to send me an email.
          </p>
        </div>
        <input
          className="text-[#000000] bg-[#e4e4e4] p-2"
          type="text"
          placeholder="(Name)"
          name="name"
        />
        <input
          className="text-[#000000] my-4 p-2 bg-[#e4e4e4]"
          type="email"
          placeholder="(Email)"
          name="email"
        />
        <textarea
          className="text-[#000000] bg-[#e4e4e4] p-2"
          name="message"
          rows="10"
          placeholder="(Message)"
        ></textarea>
        <button className="border-2 border-[#fb5700] hover:bg-[#fb5800e0] hover:text-[#ffffff] hover:border-[#fb5800e0] px-4 py-3 my-8 mx-auto flex items-center">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Contact;
