import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";



const Home = () => {
  return (
    <div name="home" className="w-full h-screen">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full ">
        <p className="text-lg text-[#000000]">Hello, welcome to</p>
        
        <h2 className="text-4xl sm:text-6xl font-bold text-[#031e65]">
          Warner Woodworks.
        </h2>
        <p className="text-lg text-[#000000] py-4 max-w-[700px]">
        My name is Eliot Warner and I'm Hardworking, Honest, and Fair with all my work.
        I have been in the carpentry trade for the last 6 years. I absolutely love what I do and genuinely enjoy working with my hands. 
        I work on every project as if it were my own house and make sure the customer is happy every step of the way. 
        I have a wide skill set and take pride in all my work.
        </p>
        <div class='flex items-center justify-center'>
        <Link to="services" smooth={true} duration={500}>
          <button  className="group border-2 border-[#fb5700] px-6 py-3 my-2 flex items-center hover:bg-[#fb5800e0] hover:text-[#ffffff] hover:border-[#fb5800e0]">
            View Services
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3 " />
            </span>
          </button>
        </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
