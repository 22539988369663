import React from "react";

import Saw from "../assets/sawicon.png";
import Hammer from "../assets/hammericon.png";
import Esaw from "../assets/esawicon.png";
import Screwdriver from "../assets/screwdrivericon.png";
import Ruler from "../assets/rulericon.png";


const Services = () => {
  return (
    <div name="services" className="w-full h-screen text-[#ffffff]">
      {/* Container*/}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl text-[#031e65] font-bold inline  ">Services</p>
          <p className="text-lg text-[#000000] py-4">
            These are the services I provide.
          </p>
        </div>

        <div className="w-full grid grid-cols-3 sm:grid-cols-3 gap-4 text-center py-8">
          <div className="bg-[#031f65dc] hover:bg-[#031f65dc] shadow-md shadow-[#3a3a3e] hover:scale-110 duration-500 ">
            <img className="w-30 mx-auto" src={Saw} alt="Saw icon" />
            <p className="my-4">CUSTOM CARPENTRY</p>
          </div>
          <div className="bg-[#031f65dc] hover:bg-[#031f65dc] shadow-md shadow-[#3a3a3e] hover:scale-110 duration-500 ">
            <img className="w-30 mx-auto" src={Hammer} alt="Hammer icon" />
            <p className="my-4">DECK / PORCH REPAIR</p>
          </div>
          <div className="bg-[#031f65dc] hover:bg-[#031f65dc] shadow-md shadow-[#3a3a3e] hover:scale-110 duration-500 ">
            <img className="w-30 mx-auto" src={Ruler} alt="Ruler icon"/>
            <p className="my-4">TRIM / MOLDING INSTALL</p>
          </div>
          <div className="bg-[#031f65dc] hover:bg-[#031f65dc] shadow-md shadow-[#3a3a3e] hover:scale-110 duration-500 ">
            <img className="w-30 mx-auto" src={Esaw} alt="Esaw icon" />
            <p className="my-4">DOOR / WINDOW INSTALL</p>
          </div>
          <div className="bg-[#031f65dc] hover:bg-[#031f65dc] shadow-md shadow-[#3a3a3e] hover:scale-110 duration-500 ">
            <img className="w-30 mx-auto" src={Screwdriver} alt="Screwdriver icon" />
            <p className="my-4">GENERAL CONTRACT</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
